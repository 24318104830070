import React, { useState } from 'react';
import { AssignedCandidate } from '../../models/assigned-candidate.interface';
import { useTranslation } from 'react-i18next';
import Pagination from '../pagination/Pagination';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store/store';
import {
  calculateCandidateMatch,
  getAssignedCandidates,
  setCandidateStatus,
} from '../../store/slices/companyCandidatesSlice';
import CandidatesTableMobile from './CandidatesTableMobile';
import {
  setClickedMatchPayload,
  setIsLimitReachedModal,
  setIsMatchWarningModal,
} from '../../store/slices/modalSlice';
import { AIModels, plans } from '../../constants/constants';
import { Image } from 'react-bootstrap';
import ActionBar from '../ui/action-bar/ActionBar';
import { ASSETS_URL } from '../../constants';
import MatchSubmitModal from '../ui/match-submit-modal/MatchSubmitModal';
import { MatchPayload } from '../../models/match-payload';
import { TableColumn } from '../../models/table-column.interface';
import CandidateTableHead from './CandidateTableHead';
import CandidateTableBody from './CandidateTableBody';
import { checkIfMatchLimitReached } from '../../utils/check-if-match-limit-reached';
import { increaseLimitCounter } from '../../store/slices/companySlice';

interface TableProps {
  candidates: AssignedCandidate[];
  columns: TableColumn[];
  handleRowCheck: (id: string) => void;
  selectAll: boolean;
  pageNumber: number;
  setPageNumberHandler: (page: number) => void;
  uncheckAll: () => void;
  checkAll: () => void;
  searchValue: string;
  maxAmount: number;
  setAmount: (value: number) => void;
  statusValue: string;
}

const CandidatesTable = ({
  candidates,
  columns, // Array to pass columns on which the data will be shown
  handleRowCheck,
  selectAll,
  pageNumber,
  setPageNumberHandler,
  uncheckAll,
  checkAll,
  searchValue,
  maxAmount,
  setAmount,
  statusValue,
}: TableProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const company = useSelector((state: RootState) => state.companyReducer);
  const [selectedMatchIds, setSelectedMatchIds] = useState<string[]>([]);
  const total = useSelector(
    (state: RootState) => state.companyCandidatesReducer.total
  );
  const barActions = [
    {
      action: () => {
        matchMultipleCandidates();
        uncheckAll();
      },
      image: null,
      text: t('candidatesTablet.actionBar.match'),
    },
    {
      action: uncheckAll,
      image: null,
      text: t('vacanciesTablet.actionBar.cancel'),
    },
  ];

  function matchMultipleCandidates(): void {
    if (
      checkIfMatchLimitReached(
        company.request_counter?.gpt_4_requests,
        company.plan
      )
    ) {
      dispatch(setIsLimitReachedModal(true));
      return;
    }

    const { matchArr, selectedMatchIds } = getSelectedForMatch();

    // @ts-ignore
    if (company.plan?.name === plans.premium.name) {
      dispatch(increaseLimitCounter(selectedMatchIds.length));
      dispatch(calculateCandidateMatch({ matchArr, selectedMatchIds }));
      return;
    }

    dispatch(setClickedMatchPayload(matchArr));
    setSelectedMatchIds(selectedMatchIds);
    dispatch(setIsMatchWarningModal(true));
  }

  const getSelectedForMatch = () => {
    const matchArr: MatchPayload[] = [];
    const selectedMatchIds: string[] = [];

    candidates
      .filter((item) => item.checked)
      .forEach((candidate: AssignedCandidate) => {
        const offerId = candidate.offer?.id;
        const candidateId = candidate.candidate.id;

        if (!offerId || !candidateId) return;

        matchArr.push({
          ai_model: AIModels.main,
          offer_id: offerId,
          candidate_id: candidateId,
        });

        selectedMatchIds.push(candidate.id || '');
      });

    return { matchArr, selectedMatchIds };
  };

function handleStatusChange(event: React.ChangeEvent<HTMLSelectElement>): void {
  const status = +event.target.value; // Convert to number
  const ids = event.target.id.split('|');
  dispatch(
    setCandidateStatus({offer_id: ids[0], candidate_id: ids[1], status: status})
  );
}


  function onPageChange(page: number): void {
    dispatch(
      getAssignedCandidates({
        pageNumber: page,
        searchValue,
        size: maxAmount,
        statusValue,
      })
    );
    setPageNumberHandler(page);
  }

  return (
    <>
      <div className='position-relative w-100'>
        <table className='app-table mt-4 tablet-hidden' cellSpacing={5}>
          <CandidateTableHead
            {...{ checkAll, uncheckAll, columns, selectAll }}
          />
          <CandidateTableBody {...{ candidates, handleRowCheck, handleStatusChange }} />
        </table>

        <div className='no-tablet-hidden'>
          <CandidatesTableMobile candidates={candidates} />
        </div>
        {candidates.filter((item) => item.checked).length ? (
          <ActionBar
            selectedCount={
              candidates.filter((item) => item.checked).length || 0
            }
          >
            {barActions.map((item) => (
              <button onClick={item.action} key={item.text}>
                {item.image && <Image src={ASSETS_URL + item.image} />}
                {item.text}
              </button>
            ))}
          </ActionBar>
        ) : null}
      </div>

      <MatchSubmitModal
        handleClose={() => dispatch(setIsMatchWarningModal(false))}
        selectedMatchIds={selectedMatchIds}
      />

      <Pagination
        totalPages={Math.ceil(total / maxAmount) || 1}
        currentPage={pageNumber}
        pageSize={maxAmount}
        withAmountSelection={true}
        setAmount={setAmount}
        changeCurrentPage={onPageChange}
      />
    </>
  );
};

export default CandidatesTable;
