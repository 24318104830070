import React, { useState } from 'react';
import './ApplicantDetail.scss';
import { Col, Row, Tabs, Tab } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../store/store';
import Language from '../../../models/language.interface';
import CandidateTag from '../../../models/candidate-tag.interface';
import Experience from '../../../models/experience.interface';
import Education from '../../../models/education.interface';
import DownloadResumeButton from '../../../components/ui/download-resume-button/DownloadResumeButton';
import CandidateInfoModal from '../../../components/ui/candidate-info-modal/CandidateInfoModal';
import RegistrationCardActions from '../../../components/ui/registration-card-actions/RegistrationCardActions';
import {
  addCertificate,
  addEducation,
  addExperience,
  deleteCertificate,
  deleteEducation,
  deleteExperience,
  deleteHardSkill,
  deleteLanguage,
  deleteSkill,
  editCertificate,
  editEducation,
  editExperience,
  updateCandidate,
} from '../../../store/slices/candidateSlice';
import CandidateExperienceModal from '../../../components/ui/candidate-experience-modal/CandidateExperienceModal';
import CandidateEducationModal from '../../../components/ui/candidate-education-modal/CandidateEducationModal';
import DotsButton from '../../../components/ui/dots-button/DotsButton';
import CandidateLanguageModal from '../../../components/ui/candidate-language-modal/CandidateLanguageModal';
import RegistrationTag from '../../../components/ui/registration-tag/RegistrationTag';
import CandidateSkillModal from '../../../components/ui/candidate-skill-modal/CandidateSkillModal';
import CandidateCertificationModal from '../../../components/ui/candidate-certification-modal/CandidateCertificationModal';
import Certificate from '../../../models/certificate.interface';
import CandidateEditHeader from '../../../components/ui/candidate-edit-header/CandidateEditHeader';
import CandidateExperienceCard from '../../../components/ui/candidate-experience-card/CandidateExperienceCard';
import CandidateEducationCard from '../../../components/ui/candidate-education-card/CandidateEducationCard';
import CandidateCertificateCard from '../../../components/ui/candidate-certificate-card/CandidateCertificateCard';
import CandidateEditInfoSection from '../../../components/ui/candidate-edit-info-section/CandidateEditInfoSection';
import CandidateEditInfoSectionMobile from '../../../components/ui/candidate-edit-info-section-mobile/CandidateEditInfoSectionMobile';
import { getLanguageLevel } from '../../../utils/get-language-level';
import { useTranslation } from 'react-i18next';
import { ListItem } from '../../../models/list-item';
import { getItemById } from '../../../utils/get-item-by-id';
import { getLanguageTranslation } from '../../../utils/languages-switch/get-language-translation';
import CandidateHardSkillModal from '../../../components/ui/candidate-hard-skill-modal/CandidateHardSkillModal';
import VacancyInfo from '../../../components/ui/vacancy-info/VacancyInfo';
import CandidateLicenseModal from '../../../components/ui/candidate-license-modal/CandidateLicenseModal';
import Option from '../../../models/option.interface';
import UploadResumeButton from '../../../components/ui/upload-resume-button/UploadResumeButton';
import {number, string} from "yup";

const ApplicantDetail = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const [editedExperienceIndex, setEditedExperienceIndex] = useState(-1);
  const [editedEducationIndex, setEditedEducationIndex] = useState(-1);
  const [editedLanguageIndex, setEditedLanguageIndex] = useState(-1);
  const [editedCertificateIndex, setEditedCertificateIndex] = useState(-1);
  const [isInfoModal, setIsInfoModal] = useState(false);
  const [isExperienceModal, setIsExperienceModal] = useState(false);
  const [isEducationModal, setIsEducationModal] = useState(false);
  const [isLanguageModal, setIsLanguageModal] = useState(false);
  const [isSkillModal, setIsSkillModal] = useState(false);
  const [isHardSkillModal, setIsHardSkillModal] = useState(false);
  const [isCertificateModal, setIsCertificateModal] = useState(false);
  const [isLicenseModal, setIsLicenseModal] = useState(false);
  const candidate = useSelector((state: RootState) => state.candidateReducer);
  const levels: ListItem[] = t('languageForm.levels', {
    returnObjects: true,
  });
  const aptitudeList: ListItem[] = t('aptitudeList', { returnObjects: true });

  function openInfoModal(): void {
    setIsInfoModal(true);
  }

  function closeInfoModal(): void {
    setIsInfoModal(false);
  }

  // Experience
  function closeExperienceModal(): void {
    setEditedExperienceIndex(-1);
    setIsExperienceModal(false);
  }

  function openExperienceModal(): void {
    setIsExperienceModal(true);
  }

  function editExperienceHandler(index: number): void {
    setEditedExperienceIndex(index);
    setIsExperienceModal(true);
  }

  const handleExperienceSubmit = async (data: Experience) => {
    if (editedExperienceIndex >= 0) {
      dispatch(editExperience({ data, editedExperienceIndex }));
    } else {
      dispatch(addExperience(data));
    }

    dispatch(updateCandidate());
    closeExperienceModal();
  };

  const deleteExperienceHandler = (index: number): void => {
    dispatch(deleteExperience(index));
    dispatch(updateCandidate());
  };

  // Education
  function editEducationHandler(index: number): void {
    setEditedEducationIndex(index);
    setIsEducationModal(true);
  }

  const handleEducationSubmit = (data: Education) => {
    if (editedEducationIndex >= 0) {
      dispatch(editEducation({ data, editedEducationIndex }));
    } else {
      dispatch(addEducation(data));
    }

    dispatch(updateCandidate());
    closeEducationModal();
  };

  const deleteEducationHandler = (index: number): void => {
    dispatch(deleteEducation(index));
    dispatch(updateCandidate());
  };

  function closeEducationModal(): void {
    setEditedEducationIndex(-1);
    setIsEducationModal(false);
  }

  function openEducationModal(): void {
    setIsEducationModal(true);
  }

  // Language
  function openLanguageModal(): void {
    setIsLanguageModal(true);
  }

  function editLanguageHandler(index: number): void {
    setEditedLanguageIndex(index);
    setIsLanguageModal(true);
  }

  function deleteLanguageHandler(index: number): void {
    dispatch(deleteLanguage(index));
    dispatch(updateCandidate());
  }

  function closeLanguageModal(): void {
    setEditedLanguageIndex(-1);
    setIsLanguageModal(false);
  }

  // Skill
  function openSkillModal(): void {
    setIsSkillModal(true);
  }

  function closeSkillModal(): void {
    setIsSkillModal(false);
  }

  function deleteSelectedTag(tag: ListItem): void {
    dispatch(deleteSkill(tag.id));
    dispatch(updateCandidate());
  }

  // hardSkill
  function openHardSkillModal(): void {
    setIsHardSkillModal(true);
  }

  function closeHardSkillModal(): void {
    setIsHardSkillModal(false);
  }

  function deleteHardSelectedTag(tag: ListItem): void {
    dispatch(deleteHardSkill(tag.id));
    dispatch(updateCandidate());
  }

  // Certificate
  function closeCertificateModal(): void {
    setEditedCertificateIndex(-1);
    setIsCertificateModal(false);
  }

  function openCertificateModal(): void {
    setIsCertificateModal(true);
  }

  function editCertificateHandler(index: number): void {
    setEditedCertificateIndex(index);
    setIsCertificateModal(true);
  }

  function deleteCertificateHandler(index: number): void {
    dispatch(deleteCertificate(index));
    dispatch(updateCandidate());
  }

  function handleCertificateSubmit(data: Certificate): void {
    if (editedCertificateIndex >= 0) {
      dispatch(editCertificate({ data, editedCertificateIndex }));
    } else {
      dispatch(addCertificate(data));
    }

    dispatch(updateCandidate());
    closeCertificateModal();
  }

  // License
  function openLicenseModal(): void {
    setIsLicenseModal(true);
  }

  function closeLicenseModal(): void {
    setIsLicenseModal(false);
  }

  return (
    <>
      <CandidateInfoModal show={isInfoModal} handleClose={closeInfoModal} />
      <CandidateExperienceModal
        index={editedExperienceIndex}
        show={isExperienceModal}
        handleSubmit={handleExperienceSubmit}
        handleClose={closeExperienceModal}
      />
      <CandidateEducationModal
        index={editedEducationIndex}
        show={isEducationModal}
        handleSubmit={handleEducationSubmit}
        handleClose={closeEducationModal}
      />
      <CandidateLanguageModal
        index={editedLanguageIndex}
        show={isLanguageModal}
        handleClose={closeLanguageModal}
      />
      <CandidateCertificationModal
        index={editedCertificateIndex}
        show={isCertificateModal}
        handleClose={closeCertificateModal}
        handleSubmit={handleCertificateSubmit}
      />
      <CandidateSkillModal show={isSkillModal} handleClose={closeSkillModal} />
      <CandidateHardSkillModal
        show={isHardSkillModal}
        handleClose={closeHardSkillModal}
      />
      <CandidateLicenseModal
        show={isLicenseModal}
        handleClose={closeLicenseModal}
      />
      <Row>
        <Col xs={12} md={3}>
          <CandidateEditInfoSection openInfoModal={openInfoModal} />
          <CandidateEditInfoSectionMobile openInfoModal={openInfoModal} />
        </Col>
        <Col xs={12} md={9}>
          <div className='zestur-card position-relative'>
            {candidate.cv[0] ? (
              <div className='download-button'>
                {/* <UploadResumeButton /> */}
                <DownloadResumeButton
                  fileUrl={candidate.cv.at(-1)?.file || ''}
                />
              </div>
            ) : (
              <div className='download-button'>
                <UploadResumeButton />
              </div>
            )}
            <Tabs
              defaultActiveKey='experience'
              id='uncontrolled-tab-example'
              className='mb-3'
            >
              <Tab
                eventKey='experience'
                title={t('candidateEditPage.tabs.experience')}
              >
                <CandidateEditHeader
                  title={t('candidateEditPage.titles.experience')}
                  openModal={openExperienceModal}
                />

                {candidate.experience.map((item: Experience, index: number) => (
                  <CandidateExperienceCard
                    key={item.id || index}
                    index={index}
                    experience={item}
                    editHandler={editExperienceHandler}
                    deleteHandler={deleteExperienceHandler}
                  />
                ))}
              </Tab>
              <Tab
                eventKey='education'
                title={t('candidateEditPage.tabs.education')}
              >
                <CandidateEditHeader
                  title={t('candidateEditPage.titles.education')}
                  openModal={openEducationModal}
                />
                {candidate.education.map((item: Education, index: number) => {
                  return (
                    <CandidateEducationCard
                      index={index}
                      education={item}
                      editHandler={editEducationHandler}
                      deleteHandler={deleteEducationHandler}
                      key={item.id || index}
                    />
                  );
                })}
                <CandidateEditHeader
                  title={t('candidateEditPage.titles.certificate')}
                  openModal={openCertificateModal}
                />
                {candidate.certificates.map(
                  (item: Certificate, index: number) => (
                    <CandidateCertificateCard
                      index={index}
                      certificate={item}
                      editHandler={editCertificateHandler}
                      deleteHandler={deleteCertificateHandler}
                      key={item.id || index}
                    />
                  )
                )}
              </Tab>
              <Tab
                eventKey='contact'
                title={t('candidateEditPage.tabs.language')}
              >
                <CandidateEditHeader
                  title={t('candidateEditPage.titles.language')}
                  openModal={openLanguageModal}
                />
                <div className='d-flex flex-column gap-2'>
                  {candidate.languages.map((item: Language, index: number) => {
                    const lang = getLanguageTranslation(item, i18n.language);
                    return (
                      <div
                        className='d-flex justify-content-between'
                        key={index}
                      >
                        <h5>
                          {lang}:
                          <span className='ms-2'>
                            {getLanguageLevel(item.level, levels)}
                          </span>
                        </h5>
                        <div className='actions gap-4 mobile-hidden'>
                          <RegistrationCardActions
                            edit={editLanguageHandler}
                            deleteCard={deleteLanguageHandler}
                            index={index}
                          />
                        </div>
                        <div className='no-mobile-hidden'>
                          <DotsButton
                            edit={editLanguageHandler}
                            deleteHandler={deleteLanguageHandler}
                            index={index}
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
                <CandidateEditHeader
                  title={t('hardSkillLabel')}
                  openModal={openHardSkillModal}
                />
                <div className='skills'>
                  {candidate.hardSkills.map(
                    (skill: CandidateTag, index: number) => {
                      const tag: ListItem = {
                        name: getItemById(aptitudeList, skill.name),
                        id: skill.name,
                      };

                      return (
                        <RegistrationTag
                          key={skill.id || index}
                          tag={tag}
                          deleteTag={deleteHardSelectedTag}
                        />
                      );
                    }
                  )}
                </div>

                <CandidateEditHeader
                  title={t('softSkillLabel')}
                  openModal={openSkillModal}
                />
                <div className='skills'>
                  {candidate.skills.map(
                    (skill: CandidateTag, index: number) => {
                      const tag: ListItem = {
                        name: getItemById(aptitudeList, skill.name),
                        id: skill.name,
                      };
                      return (
                        <RegistrationTag
                          key={skill.id || index}
                          tag={tag}
                          deleteTag={deleteSelectedTag}
                        />
                      );
                    }
                  )}
                </div>

                <CandidateEditHeader
                  title={t('vacancyDetails.subtitles.license')}
                  openModal={openLicenseModal}
                />
                <div className='details__info'>
                  <div>
                    <VacancyInfo
                      title={`${t('vacancyDetails.labels.license')}:`}
                      info={
                        candidate.license.license?.length
                          ? (
                              candidate.license.license.map(
                                (item: Option) => item?.name || item
                              ) as unknown as string[]
                            ).join(', ')
                          : t('licenseForm.noLicense')
                      }
                    />
                    <VacancyInfo
                      title={`${t('licenseForm.vehicleLabel')}:`}
                      info={
                        candidate.license.isVehicle
                          ? t('licenseForm.secondOption')
                          : t('licenseForm.firstOption')
                      }
                    />
                  </div>
                </div>
              </Tab>
            </Tabs>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default ApplicantDetail;
