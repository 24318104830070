import { plans } from '../constants/constants';

export const getCurrentPlanMatchLimit = (plan?: object): number => {
  if (plan) {
    // @ts-ignore
    if (plan.duration !== 365) {
      return 50;
    }
    return 500;
  }
  if (!plan || plan === plans.trial.name) return 5;
  return 500;
};
