import React, {useState} from 'react';

import './ProfileCompany.scss';
import {Col, Image, Modal, Row} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SmartPlanModal from "../../../components/ui/smart-plan-modal/SmartPlanModal";
import {CompanyState, setPlanType} from "../../../store/slices/companySlice";
import {toast} from "react-toastify";
import {sendResumeToAI, setResumeAnalysing} from "../../../store/slices/candidateSlice";
import {useDispatch} from "react-redux";
import {AppDispatch} from "../../../store/store";
import {ASSETS_URL} from "../../../constants";
import icDone from "../../../assets/images/ic_done.svg";

const ProfilePlansCompany = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [showSmartModal, setShowSmartModal] = useState(false);
  const [showMonthlyModal, setShowMonthlyModal] = useState(false);
  const [showYearlyModal, setShowYearlyModal] = useState(false);
  const handleCloseSmartModal = () => setShowSmartModal(false);
  const handleShowSmartModal = () => setShowSmartModal(true);
  const handleCloseMonthlyModal = () => setShowMonthlyModal(false);
  const handleShowMonthlyModal = () => setShowMonthlyModal(true);
  const handleCloseYearlyModal = () => setShowYearlyModal(false);
  const handleShowYearlyModal = () => setShowYearlyModal(true);

  const defaultPlanBenefits: string[] = t('planPage.default.benefits', {
    returnObjects: true,
  });
  const monthlyPlanBenefits: string[] = t('planPage.default.monthlybenefits', {
    returnObjects: true,
  });
  const smartPlanBenefits: string[] = t('planPage.smart.benefits', {
    returnObjects: true,
  });

  const handleSubmitSmartModal = async (data: CompanyState)=> {
    // todo - send company data when there is a back end email to send
    //             dispatch(
    //               sendSmartQuoteEmail({ company: data })
    //             );
    toast.success(t('smartModal.messageSent'));
  };

  const handleSubmit = async (planType: string) => {
    setShowYearlyModal(false);
    setShowMonthlyModal(false);
    const response = await dispatch(setPlanType(planType));
    console.log(response);
    const jsonString = JSON.stringify(response.payload);
    const base64String = btoa(jsonString);
    window.open('http://' + window.location.hostname + ':8001/prepayment/' + base64String, '_blank');
  };

  function closeSmartModal(): void {
    setShowSmartModal(false);
  }

  return (
    <div className='zestur-card'>
      <h2 className='general-heading mt-4 mb-4'>{t('planPage.title')}</h2>
      <p className='general-sub-heading'>{t('planPage.description')}</p>
      <Row className='mt-4 tablet-gap-1'>
        <Col xs={12} lg={4}>
          <div className='plans-container'>
            <div className='plans-container-header'>
              <div className='plans-container-header__title'>
                {t('planPage.default.title')}
              </div>
              <div className='plans-container-header__rate'>
                {t('planPage.default.monthlyprice')}
                <span className='plans-container-header__subrate'>
                  {t('planPage.default.yearlyprice')}
                </span>
              </div>
            </div>
            <div className='plans-container-content mt-4'>
              <ul>
                {monthlyPlanBenefits.map((item) => (
                  <li key={item}>{item}</li>
                ))}
              </ul>
            </div>
            <div className='d-flex align-items-center justify-content-center mt-5 mb-3'>
              <button
                className='btn btn-primary primary-btn-color w-100'
                onClick={handleShowMonthlyModal}
              >
                {t('planPage.default.button')}
              </button>
            </div>
          </div>
        </Col>
        <Col xs={12} lg={4}>
          <div className='plans-container'>
            <div className='plans-container-header'>
              <div className='plans-container-header__title'>
                {t('planPage.default.title')}
              </div>
              <div className='plans-container-header__rate'>250€&nbsp;{t('planPage.annual')}</div>
            </div>
            <div className='plans-container-content mt-4'>
              <ul>
                {defaultPlanBenefits.map((item) => (
                  <li key={item}>{item}</li>
                ))}
              </ul>
            </div>
            <div className='d-flex align-items-center justify-content-center mt-5 mb-3'>
              <button
                className='btn btn-primary primary-btn-color w-100'
                onClick={handleShowYearlyModal}
              >
                {t('planPage.default.button')}
              </button>
            </div>
          </div>
        </Col>
        <Col xs={12} lg={4}>
          <div className='plans-container bg'>
            <div className='plans-container-header'>
              <div className='plans-container-header__title'>
                {t('planPage.smart.title')}
              </div>
              <div className='plans-container-header__rate'>
                {t('planPage.smart.name')}
              </div>
            </div>
            <div className='plans-container-content mt-4'>
              <ul>
                {smartPlanBenefits.map((item) => (
                  <li key={item}>{item}</li>
                ))}
              </ul>
            </div>
            <div className='d-flex align-items-center justify-content-center mt-5 mb-3'>
              <button
                className='btn btn-primary bg-white text-primary w-100'
                onClick={handleShowSmartModal}
              >
                {t('planPage.smart.button')}
              </button>
            </div>
          </div>
        </Col>
      </Row>
      <p className='general-sub-heading mt-4 mb-4'>{t('planPage.postWord')}</p>
      <SmartPlanModal
        show={showSmartModal}
        handleClose={handleCloseSmartModal}
        handleSubmit={handleSubmitSmartModal}
      />
      <Modal show={showMonthlyModal} centered onHide={handleCloseMonthlyModal}>
        <Modal.Body className='text-center py-5'>
          <h1 className='fw-bold my-4'>{t('planPage.paymentheading')}</h1>
          <p className='f-12 mt-4'>{t('planPage.paymentbody')}</p>
          <button
            className='btn mt-4 mb-5 btn-primary primary-btn-color px-5 py-3'
            onClick={() => handleSubmit('monthly')}
          >
            {t('planDetails.modal.acceptButton')}
          </button>
        </Modal.Body>
      </Modal>
      <Modal show={showYearlyModal} centered onHide={handleCloseYearlyModal}>
        <Modal.Body className='text-center py-5'>
          <h1 className='fw-bold my-4'>{t('planPage.paymentheading')}</h1>
          <p className='f-12 mt-4'>{t('planPage.paymentbody')}</p>
          <button
            className='btn mt-4 mb-5 btn-primary primary-btn-color px-5 py-3'
            onClick={() => handleSubmit('yearly')}
          >
            {t('planDetails.modal.acceptButton')}
          </button>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ProfilePlansCompany;
