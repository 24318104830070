import React, { useEffect, useState } from 'react';
import QRCode from 'qrcode.react';
import mergeImages from 'merge-images';
import ReactDOM from 'react-dom/client';
import backgroundImage1 from '../../../assets/images/qrbackground1_es.svg'; // First background image
import backgroundImage2 from '../../../assets/images/qrbackground2_es.svg';
import {useTranslation} from "react-i18next"; // Second background image

interface MergeImageWithQRProps {
  qrCodeUrl: string; // QR code URL passed as a prop
}

const MergeImageWithQR: React.FC<MergeImageWithQRProps> = ({ qrCodeUrl }) => {
  const [qrImage, setQRImage] = useState<string | null>(null);
  const [mergedImage1, setMergedImage1] = useState<string | null>(null);
  const [mergedImage2, setMergedImage2] = useState<string | null>(null);
  const { t } = useTranslation();

  useEffect(() => {
    const generateMergedImage = async (backgroundSrc: string, yPos: number): Promise<string> => {
      // Generate QR Code Canvas
      const qrCodeCanvas = document.createElement('canvas');

      let qrSize = 370;
      if (window.innerWidth < 400) {
        qrSize = 120;
      } else if (window.innerWidth < 860) {
        qrSize = 175;
      } else if (window.innerWidth <= 1600) {
        qrSize = 185;
      }

      const qrCodeComponent = (
        <QRCode
          value={qrCodeUrl} // Use the QR code URL from the prop
          size={qrSize} // QR code size
          bgColor="#ffffff"
          fgColor="#000000"
          includeMargin={true}
        />
      );

      const renderToCanvas = (element: JSX.Element, canvas: HTMLCanvasElement) => {
        return new Promise<void>((resolve) => {
          const container = document.createElement('div');
          container.style.position = 'absolute';
          container.style.visibility = 'hidden';
          document.body.appendChild(container);

          const root = ReactDOM.createRoot(container);
          root.render(element);

          setTimeout(() => {
            const canvasElement = container.querySelector('canvas') as HTMLCanvasElement;
            if (canvasElement) {
              canvas.width = canvasElement.width;
              canvas.height = canvasElement.height;
              const context = canvas.getContext('2d');
              if (context) {
                context.drawImage(canvasElement, 0, 0);
              }
            }

            root.unmount();
            document.body.removeChild(container);
            resolve();
          }, 100);
        });
      };

      await renderToCanvas(qrCodeComponent, qrCodeCanvas);

      setQRImage(qrCodeCanvas.toDataURL());

      // Merge QR code with background image
      return mergeImages([
        { src: backgroundSrc, x: 0, y: 0 },
        { src: qrCodeCanvas.toDataURL(), x: 100, y: yPos } // Position QR code
      ]);
    };

    // Generate merged images for both backgrounds
    const generateImages = async () => {
      const mergedImage1Uri = await generateMergedImage(backgroundImage1, 130);
      setMergedImage1(mergedImage1Uri);

      const mergedImage2Uri = await generateMergedImage(backgroundImage2, 70);
      setMergedImage2(mergedImage2Uri);
    };

    generateImages();
  }, [qrCodeUrl]);

  // Function to handle image download
  const downloadImage = (imageUri: string | null, filename: string) => {
    if (imageUri) {
      const link = document.createElement('a');
      link.href = imageUri;
      link.download = filename;
      link.click();
    }
  };

  return (
    <div>
      <div style={{ display: 'flex', gap: '20px' }}>
        {/* First Merged Image */}
        <div>
          {mergedImage1 ? (
            <div>
              <img src={mergedImage1} alt="QR Code 1" style={{ display: 'block', marginBottom: '10px', maxWidth: '100%'}} />
              <button
                className='btn btn-primary btn-color-primary'
                onClick={() => downloadImage(mergedImage1, 'zasjobs-qrcode1.png')}
                style={{ padding: '10px 20px', fontSize: '16px', cursor: 'pointer' }}
              >
                {t('companyHomePage.profile.download')}
              </button>
            </div>
          ) : (
            <p>Generating...</p>
          )}
        </div>

        {/* Second Merged Image */}
        <div>
          {mergedImage2 ? (
            <div>
              <img src={mergedImage2} alt="QR Code 2" style={{ display: 'block', marginBottom: '10px', maxWidth: '100%' }} />
              <button
                className='btn btn-primary btn-color-primary'
                onClick={() => downloadImage(mergedImage2, 'zasjobs-qrcode2.png')}
                style={{ padding: '10px 20px', fontSize: '16px', cursor: 'pointer' }}
              >
                  {t('companyHomePage.profile.download')}
              </button>
            </div>
          ) : (
            <p>Generating...</p>
          )}
        </div>
      </div>
      <p className={'f-12 mt-4'}>
        <a href='#' onClick={() => downloadImage(qrImage, 'zasjobs-qrcode.png')}>{t('companyHomePage.profile.download')} {t('qrModal.title')}</a>
      </p>
    </div>
  );
};

export default MergeImageWithQR;
