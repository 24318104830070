import React from 'react';
import { Image, Modal, Form } from 'react-bootstrap';
import { ASSETS_URL } from '../../../constants';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import icCopy from '../../../assets/icons/ic-copy.svg';
import './CompanyQRModal.scss';
import { useTranslation } from 'react-i18next';
import { config } from '../../../_config/config';
import { toast } from 'react-toastify';
import MergeImageWithQR from "./MergeImageWithQR";

interface Props {
  show: boolean;
  handleClose: () => void;
}

const CompanyQRModal = ({ show, handleClose }: Props) => {
  const { t } = useTranslation();
  const company = useSelector((state: RootState) => state.companyReducer);
  const inputUrl = config.APP_URL + '/candidate?company=' + company.name;

  return (
    <Modal show={show} centered onHide={handleClose}>
      <Modal.Body className='text-center py-5'>
        <h1 className='fw-bold my-4'>{t('qrModal.title')}</h1>
        <p className='f-12 mt-4'>{t('qrModal.subtitle')}</p>
        <div className='mt-4 position-relative'>
          <MergeImageWithQR qrCodeUrl={inputUrl}/>
          {company.logo && (
            <Image
              className='qr-logo position-absolute rounded-circle object-fit-cover'
              src={ASSETS_URL + company.logo}
            />
          )}
        </div>
        <div className='d-flex align-items-center justify-content-center mt-4'>
          <Form.Group className='me-2' controlId='exampleForm.ControlInput1'>
            <Form.Control
              type='text'
              value={inputUrl}
              placeholder=''
              readOnly
            />
          </Form.Group>
          <button
            className='btn btn-primary primary-btn-color'
            onClick={() => {
              navigator.clipboard.writeText(inputUrl);
              toast.success(t('messages.clipboardSaved'));
            }}
          >
            <Image src={ASSETS_URL + icCopy} />
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CompanyQRModal;
